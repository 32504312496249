import Home from '../components/Home'
import axios from 'axios';
import { useRouter } from 'next/router'

export const HomePage = props => {
  const router = useRouter()

  return(
      <Home
        key={router.asPath}
        data={props.data}
      />
  )
}
export default HomePage

export async function getStaticProps() {

  try {
    const postsRes = axios.get(
      process.env.NEXT_PUBLIC_FASTLY + '/api/v1/posts'
    )
    const newslettersRes = axios.get(
      process.env.NEXT_PUBLIC_FASTLY + '/api/v1/newsletters'
    )
    const res = await Promise.all([postsRes, newslettersRes]) 

    return {
      props: {
        data: { posts: res[0].data, newsletters: res[1].data }
      },
      revalidate: 3200,
    }
  } catch (error) {
    return {
      props: {
        notFound: true
      }
    }
  }

}
