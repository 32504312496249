import React, { Component, useEffect, useContext, useState } from 'react';

export function DelayRender({ children }) {
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsRendered(true);
    }, 100);

    return () => clearTimeout(timer); // This will clear the timer when the component unmounts
  }, []);

  if (!isRendered) {
    return null; // or you can return a loading spinner or placeholder content
  }

  return (
    <>
      {children}
    </>
  );
}

export default DelayRender;
