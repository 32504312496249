import React, { useEffect, useRef, useState, useContext } from "react";
import Link from 'next/link'
import { useQuery, useManualQuery, useMutation } from 'graphql-hooks'
import { Store } from '../../store'
import Notifications from './header_buttons/Notifications'
import UserMenu from "./UserMenu";
import {
  Button,
} from "@material-tailwind/react";

export const UserHeaderButtons = ({}) => {
  const { state, dispatch } = useContext(Store)

  return (
    <div className="w-full relative flex items-center justify-center h-16">
      <div className="grow" />
      {state.currentUser ? (
        <>
          <Notifications />
          <UserMenu />
        </>
      ) : (
        <Button variant="outlined" size="sm" className="md:mr-6 mr-2 border-gray-400 py-1" onClick={() => {window.location.href = '/login'}}>
          ログイン
        </Button>
      )}
    </div>
  );
}

export default UserHeaderButtons;
